import React from 'react';
import styled from '@emotion/styled';
import ApplyTemplate from 'components/Templates/ApplyTemplate';
import { graphql, withPrefix } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import {
  IconPublic1,
  IconPublic2,
  IconPublic3,
  IconPublic4,
} from 'components/Atom/Icon';
import { Helmet } from 'react-helmet';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface AgencyProps {
  location: {
    pathname: string;
  };
  data: {
    logoNerdFactoryImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    topFunctionImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img1: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img2_1: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img2_2: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img3: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img4: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img5: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img6: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    img7: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    thumbnail: {
      publicURL: string;
    };
  };
}

const Agency = ({ data, location }: AgencyProps) => {
  const title = 'VODA | 적용 | 정부/공공기관';
  const desc =
    '국민의 만족도는 올라가고, 운영예산은 절감하는 정부/공공기관 사이트';
  return (
    <Component>
      <Helmet>
        <title>VODA | 적용 | 정부/공공기관</title>
        <meta
          name="description"
          content="국민의 만족도는 올라가고, 운영예산은 절감하는 정부/공공기관 사이트"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />

        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="<https://voda.nerdfactory.ai/apply/agency>"
        />
        <meta property="og:image" content={data.thumbnail.publicURL} />
        <meta property="og:description" content={desc} />
        <meta property="og:site_name" content={title} />
        <script src={withPrefix('script.js')} type="text/javascript" />
      </Helmet>
      <ApplyTemplate
        location={location}
        lookingForList={{
          title: `대한민국 정부/공공기관들이\nVODA를 찾는 이유는 무엇일까요?`,
          list: [
            {
              Icon: IconPublic1,
              desc: `접근성 및 편의성 등\n대국민 서비스 개선`,
            },
            {
              Icon: IconPublic2,
              desc: `현황 및 실태 분석으로\n사이트 운영계획 수립`,
            },
            {
              Icon: IconPublic3,
              desc: `콘텐츠의\n대국민 수요 분석`,
            },
            {
              Icon: IconPublic4,
              desc: `UI/UX 품질개선으로\n기관 이미지 제고`,
            },
          ],
        }}
        title="VODA 많은 이들을\n만족시키다"
        header={{
          sub: 'VODA 많은 이들을 만족시키다',
          main: '국민의 만족도는 올라가고,\\n운영예산은 절감하는\\n정부/공공기관 사이트',
          long: 'VODA는 범국민적 수요와 접속형태를 분석하여\\n콘텐츠의 품질을 확보할 수 있고, 기관의 신뢰성을 높입니다.\\n또한 웹사이트 현황을 명확히 파악하고,\\n유지보수 예산을 효율적으로 집행하도록\\n‘사이트 운영계획’을 체계적으로 수립할 수 있습니다.',
        }}
        img={data.topFunctionImg.childImageSharp.fluid}
        logoNerdFactoryImg={data.logoNerdFactoryImg.childImageSharp.fluid}
        functionData={[
          {
            IllustImg: data.img1.childImageSharp.fluid,
            descData: {
              title: '‘모두’를 위한 데이터\\n사용환경 분석',
              list: [
                {
                  title: '어느 지역의 사용자가 가장 많이 접속했을까?',
                  desc: '국내 접속 도시를 분석하여지역별 사용자의 니즈를 충족시킬 수 있습니다.',
                },
                {
                  title:
                    '어떤 디바이스로, 운영체제로, 브라우저로 많이 접속했을까?',
                  desc: '사용자 접근성을 고려하여 범국민적 서비스를 제공할 수 있습니다.',
                },
              ],
              to: '/functions/environmentAnalysis',
              buttonTitle: '관련 기능 더 알아보기',
            },
            subTitle:
              '국민들에게 제공하는 서비스의 품질과 만족도를 높이고, 신뢰를 얻으려면?',
            mainTitle:
              '‘국민에게 신뢰받는’ 홈페이지 운영, VODA가 있다면 어렵지않아요',
            isBackGround: false,
            isDescLeft: true,
            width: 648,
            key: '0',
          },
          {
            IllustImg: data.img3.childImageSharp.fluid,
            descData: {
              title: '시선을 읽다\\n마우스 스크롤',
              list: [
                {
                  title: '국민들은 어떤 흐름으로 페이지를 탐색할까?',
                  desc: '사용자 시선 흐름을 분석하여 페이지 내 체류시간, 유효 클릭률, 관심 콘텐츠를 파악할 수 있습니다.',
                },
              ],
              to: '/functions/uxAnalysis',
              buttonTitle: '관련 기능 더 알아보기',
            },

            isBackGround: false,
            isDescLeft: true,
            width: 648,
            key: '2',
          },
        ]}
        recommendList={[
          {
            title: '정부부처 대표 홈페이지',
            desc: '정보제공부터 민원 해결 서비스까지\n국민을 만족시키는 홈페이지,\n어렵지 않습니다. 지금 진단해보세요.',
            img: data.img4.childImageSharp.fluid,
            key: '0',
          },
          {
            title: '연구기관 홈페이지',
            desc: '혁신적인 연구 성과, 보고서 등\n얼마나 알려지고 있는지 궁금하세요?\nVODA가 알려드립니다.',
            img: data.img5.childImageSharp.fluid,
            key: '1',
          },
          {
            title: '지자체 대표 홈페이지',
            desc: '살기 좋은 우리 도시,\n온라인 서비스도 잘 운영되고 있을까?\n궁금하시다면 VODA를 설치해보세요.',
            img: data.img6.childImageSharp.fluid,
            key: '2',
          },
          {
            title: '공기업/공공기관 홈페이지',
            desc: '기관의 아이덴티티, 역할, 비전 등을\n더 많은 사람들에게 알리고 싶다면?\nVODA가 해답을 드립니다.',
            img: data.img7.childImageSharp.fluid,
            key: '3',
          },
        ]}
      />
    </Component>
  );
};

export default Agency;

export const queryData = graphql`
  query {
    logoNerdFactoryImg: file(name: { eq: "logo-nerd-factory@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    topFunctionImg: file(name: { eq: "img-top-apply@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img1: file(name: { eq: "img-region-v2" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img3: file(name: { eq: "img-scroll-v2" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img4: file(name: { eq: "img-public-4@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img5: file(name: { eq: "img-public-5@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img6: file(name: { eq: "img-public-6@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img7: file(name: { eq: "img-public-7@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thumbnail: file(name: { eq: "thumbnail-1280-720" }) {
      publicURL
    }
  }
`;
